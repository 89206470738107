import React from "react"
import "typeface-lato"
import "typeface-roboto-mono"
import "typeface-sacramento"
import "typeface-quicksand"
import "typeface-handlee"
import { ThemeProvider } from "./src/context/ThemeContext"

/* eslint-disable import/prefer-default-export */
export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

import React, { useState } from "react"

const defaultState = {
  dark: false,
  notFound: false,
  toggleDark: () => {},
}

const ThemeContext = React.createContext(defaultState)

const ThemeProvider = (props) => {
  let lsDark = false
  if (localStorage.getItem("dark"))
    lsDark = JSON.parse(localStorage.getItem("dark"))

  const [dark, setDark] = useState(lsDark)

  function toggleDarkMode() {
    setDark(!dark)
    localStorage.setItem("dark", JSON.stringify(!dark))
  }

  const { children } = props
  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark: toggleDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }
